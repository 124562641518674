import React, { ReactElement, useEffect, useState } from 'react';
import HelloSignEmbedded from 'hellosign-embedded';
import useFetch from 'use-http';
import { useParams } from 'react-router-dom';
import styles from './HelloSign.module.scss';

declare module 'hellosign-embedded';

const skipDomainVerification: boolean =
    process.env.REACT_APP_SKIP_DOMAIN_VERIFICATION === 'true';

type Params = {
    id: string;
    hash: string;
};

type IframeState = {
    open: boolean;
    finished: boolean;
};

type HelloSignState = {
    url?: string;
    clientId?: string;
};

function HelloSign(): ReactElement {
    const { id, hash } = useParams<Params>();
    const { loading, error, data } = useFetch(
        `/api?id=${id}&hash=${hash}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        },
        [id],
    );
    const [{ url, clientId }, setHelloSignState] = useState<HelloSignState>({});
    const [errorMessage, setErrorMessage] = useState('');
    useEffect(() => {
        if (data?.url) {
            setHelloSignState({ url: data.url, clientId: data.clientId });
        } else if (data?.error) {
            setErrorMessage(data.error);
        }
    }, [data]);

    const [{ open, finished }, setIframeState] = useState<IframeState>({
        open: false,
        finished: false,
    });

    useEffect(() => {
        if (url && clientId) {
            const client = new HelloSignEmbedded({ clientId });

            client.open(url, { skipDomainVerification });

            client.on('open', () => {
                setIframeState((prev) => ({
                    ...prev,
                    open: true,
                }));
            });
            client.on('close', () => {
                setIframeState((prev) => ({
                    ...prev,
                    open: false,
                }));
            });
            client.on('finish', () => {
                setIframeState((prev) => ({
                    ...prev,
                    finished: true,
                }));
            });
        }
    }, [url, clientId]);

    return (
        <main className={styles.main}>
            {loading && <h1>Preparing signature ...</h1>}
            {errorMessage && (
                <p className={styles.error}>Error: {errorMessage}</p>
            )}

            {finished && (
                <>
                    <h3>Thank you for signing the release form</h3>
                    <p>You can close this window now.</p>
                </>
            )}

            {!finished &&
                (open
                    ? !loading && !error && <h1>Signing</h1>
                    : !loading &&
                      !error && (
                          <>
                              <h3>Signing process canceled</h3>
                              <p>
                                  Please reload this page, if you want to start
                                  again.
                              </p>
                          </>
                      ))}
        </main>
    );
}

export default HelloSign;
