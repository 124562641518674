import React, { ReactElement } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import styles from './App.module.scss';
import HelloSign from '../HelloSign';

function App(): ReactElement {
    return (
        <div className={styles.app}>
            <img className={styles.logo} alt="logo" src="/logo192.png" />
            <Router>
                <Switch>
                    <Route path="/:id/:hash">
                        <HelloSign />
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
